<template>
    <div>
        <b-row class="p-2 mt-0 mb-1">
            <b-col
                v-if="isSuperAdmin"
                xs="12"
                sm="6"
            >
                <b-input-group size="sm" class="mb-2">
                    <b-input-group-prepend>
                        <b-input-group-text
                            title="Nur bestimmten Klient anzeigen."
                            :class="filter.client !== null ? 'font-weight-bold' : ''"
                        >
                            Klient?
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                        v-model="filter.client"
                        data-test="client"
                        placeholder="Für welchen Klienten?"
                        :options="availableClients"
                        value-field="@id"
                        text-field="name"
                    >
                        <template #first>
                            <b-form-select-option :value="null">Alle Klienten</b-form-select-option>
                        </template>
                    </b-form-select>
                    <my-input-group-append
                        @click="filter.client = null"
                        :is-active="filter.client !== null"
                    />
                </b-input-group>
            </b-col>
            <b-col
                xs="12"
                :sm="isSuperAdmin ? 6 : 12"
            >
                <b-input-group size="sm" class="">
                    <b-input-group-prepend>
                        <b-input-group-text
                            title="Nur aktivierte Accounts?"
                            :class="filter.isEnabled !== true ? 'font-weight-bold' : ''"
                        >
                            Nur aktivierte?
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                        v-model="filter.isEnabled"
                        :options="isEnabledOptions"
                    />
                    <my-input-group-append
                        @click="filter.isEnabled = true"
                        :is-active="filter.isEnabled !== true"
                    />
                </b-input-group>
            </b-col>
        </b-row>
        <div
            v-if="isLoading"
            class="d-flex justify-content-center my-3"
        >
            <b-spinner
                v-show="isLoading"
                style="width: 3rem; height: 3rem;"
                label="is Loading Spinner"
            />
        </div>
        <b-table
            v-show="!isLoading && users.length"
            :items="users"
            :fields="fields"
            small
            striped
            class="mb-0"
            :stacked="this.isSuperAdmin ? 'xl' : 'lg'"
        >
            <template v-slot:cell(username)="row">
                <span
                    :class="{ 'text-muted': !row.item.isEnabled }"
                    :title="!row.item.isEnabled ? 'Account ist aktuell nicht aktiviert.' : ''"
                >
                    {{ row.item.username }}
                    <v-icon
                        v-if="!row.item.isEnabled"
                        class="text-muted"
                        size="16"
                    >
                        MdiAccountOff
                    </v-icon>
                </span>
            </template>
            <template v-slot:cell(isEnabled)="row">
                <v-progress-circular
                    v-if="isLoadingToggleUserState(row.item['@id'])"
                    indeterminate
                    color="secondary"
                />
                <div
                    v-else
                    @click="toggleEnabled(row.item, row.item.isEnabled)"
                    :title="`Account ${ row.item.isEnabled ? 'de' : '' }aktivieren`"
                    class="cursor-pointer"
                >
                    <v-icon
                        v-if="row.item.isEnabled"
                        color="success"
                    >
                        mdi-account-check-outline
                    </v-icon>
                    <v-icon
                        v-else
                        color="info"
                    >
                        mdi-account-off-outline
                    </v-icon>
                </div>
            </template>
            <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-around">
                    <b-dropdown
                        text="Aktionen"
                        size="sm"
                    >
                        <b-dropdown-item-button
                            size="sm"
                            @click="editUser(row.item)"
                            :disabled="isLoadingToggleUserState(row.item['@id'])"
                        >
                            Account bearbeiten
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            v-if="isAdmin"
                            size="sm"
                            class="mr-2"
                            @click="toggleEnabled(row.item, row.item.isEnabled)"
                            :disabled="isLoadingToggleUserState(row.item['@id'])"
                        >
                            Account {{ row.item.isEnabled ? 'de' : '' }}aktivieren
                        </b-dropdown-item-button>
                        <b-dropdown-divider
                            v-if="isSuperAdmin && false"
                        />
                        <b-dropdown-item-button
                            v-if="isSuperAdmin && false"
                            @click="editUser(row.item)"
                            size="sm"
                            variant="danger"
                            :disabled="isLoadingToggleUserState(row.item['@id'])"
                        >
                            <mdicon
                                name="AlertDecagramOutline"
                            />
                            Löschen
                        </b-dropdown-item-button>
                    </b-dropdown>
                    <v-btn
                        v-if="isSuperAdmin && !isUserSwitched"
                        small
                        class="flex-item d-flex align-items-center ml-2"
                        :data-test="`switch-user-${row.item.username}`"
                        @click="switchUser(row.item)"
                    >
                        <v-icon
                            color="secondary"
                            small
                            class="p-1 mr-1 cursor-pointer flex-item"
                        >
                            mdi-account-switch
                        </v-icon>
                        Nutzer wechseln
                    </v-btn>
                    <v-btn
                        v-else-if="isUserSwitched"
                        small
                        color="secondary"
                        class="flex-item d-flex align-items-center ml-2"
                        data-test="exit-switch-user"
                        @click="exitSwitchUser()"
                    >
                        <v-icon
                            small
                            class="p-1 mr-1 cursor-pointer flex-item"
                        >
                            mdi-account-switch-outline
                        </v-icon>
                        Nutzerwechsel beenden
                    </v-btn>
                </div>
            </template>
        </b-table>

        <b-modal
            :id="editModal.id"
            :title="editModal.title"
            size="lg"
            @hide="resetEditModal"
            title="Benutzer ändern"
            hide-footer
        >
            <user-form
                v-if="editModal.selectedUser"
                submit-button-text="Speichern"
                :initial-user="editModal.selectedUser"
                @submit="handleSubmit"
            />
        </b-modal>
    </div>
</template>

<script>
'use strict';
import UserForm from './UserForm.vue';
import dayjs from 'dayjs';
import MyInputGroupAppend from '../Common/MyInputGroupAppend.vue';
import {useAlertStore, useAuthStore, useClientStore, useUserStore} from '../../stores';

export default {
    name: 'UserList',
    components: {
        UserForm,
        MyInputGroupAppend,
    },
    data: function () {
        return {
            alertStore: useAlertStore(),
            authStore: useAuthStore(),
            clientStore: useClientStore(),
            userStore: useUserStore(),
            editModal: {
                selectedUser: {},
                id: 'edit-modal-user',
                title: '',
            },
            isEnabledOptions: [
                { value: null, text: 'egal' },
                { value: true, text: 'ja' },
                { value: false, text: 'nur deaktivierte Accounts' },
            ],
            filter: {
                client: null,
                isEnabled: true,
            },
        };
    },
    computed: {
        availableClients() {
            return this.clientStore.getClients;
        },
        fields() {
            return [
                {
                    key: 'username',
                    label: 'Benutzername',
                    sortable: true,
                    class: 'text-center',
                },
                {
                    key: 'teams',
                    sortable: true,
                    formatter: (value, key, item) => {
                        let teamNames = [];
                        value.forEach(team => {
                            teamNames.push(team.name);
                        });

                        if (teamNames.length) {
                            return teamNames.join(', ');
                        }

                        return '-';
                    },
                    sortByFormatted: true,
                    class: 'text-center',
                },
                {
                    key: 'email',
                    label: 'E-Mail',
                    sortable: true,
                    class: 'text-center',
                },
                {
                    key: 'roles',
                    label: 'Rollen',
                    formatter: (value, key, item) => {
                        return value
                            .map(value => {
                                switch (value) {
                                    case 'ROLE_USER':
                                        return 'Benutzer';
                                    case 'ROLE_ADMIN':
                                        return 'Administrator';
                                    case 'ROLE_SUPER_ADMIN':
                                        return 'Super-Administrator';
                                    case 'ROLE_ALLOWED_TO_SWITCH':
                                        return 'Impersonator';
                                }

                                return value;
                            })
                            .sort((a, b) => a > b ? 1 : -1)
                            .join(', ');
                    },
                    sortable: true,
                    sortByFormatted: true,
                    class: 'text-center',
                },
                {
                    key: 'isEnabled',
                    label: 'Account aktiviert?',
                    sortable: true,
                    class: 'text-center',
                },
                {
                    key: 'client',
                    label: 'Klient',
                    sortable: true,
                    sortByFormatted: true,
                    class: !this.isSuperAdmin ? 'd-none text-center' : 'text-center',
                    formatter: this.clientFormatter,
                },
                {
                    key: 'createdAt',
                    label: 'Erstellt am',
                    sortable: true,
                    sortByFormatted: false,
                    formatter: (value, key, item) => {
                        return dayjs(value).format('DD.MM.YYYY HH:mm:ss');
                    },
                    class: 'text-center',
                },
                {
                    key: 'updatedAt',
                    label: 'Geändert am',
                    sortable: true,
                    sortByFormatted: false,
                    formatter: (value, key, item) => {
                        return dayjs(value).format('DD.MM.YYYY HH:mm:ss');
                    },
                    class: !this.isSuperAdmin ? 'd-none text-center' : 'text-center',
                },
                {
                    key: 'createdBy',
                    label: 'Erstellt von:',
                    sortable: true,
                    sortByFormatted: true,
                    class: !this.isSuperAdmin ? 'd-none text-center' : 'text-center',
                },
                {
                    key: 'updatedBy',
                    label: 'Geändert von:',
                    sortable: true,
                    sortByFormatted: true,
                    class: !this.isSuperAdmin ? 'd-none text-center' : 'text-center',
                },
                { key: 'actions', label: 'Aktionen', class: 'text-center' },
            ];
        },
        isUserSwitched() {
            return this.authStore.isUserSwitched;
        },
        isSuperAdmin() {
            return this.authStore.isSuperAdmin;
        },
        isAdmin() {
            return this.authStore.isAdmin;
        },
        users() {
            return this.userStore.getUsers
                .filter(user => !this.filter.client || this.filter.client === user.client)
                .filter(user => null === this.filter.isEnabled || this.filter.isEnabled === user.isEnabled)
                .slice()
                .sort((userA, userB) => {
                    if (userA.isEnabled === userB.isEnabled) {
                        if (userA.username.toUpperCase() < userB.username.toUpperCase()) {
                            return -1;
                        }
                    } else if (userA.isEnabled && !userB.isEnabled) {
                        return -1;
                    } else if (!userA.isEnabled && userB.isEnabled) {
                        return 1;
                    }
                })
                ;
        },
        isLoading() {
            return this.userStore.isLoading;
        },
    },
    created() {
        this.userStore.fetchUsers();
    },
    methods: {
        isLoadingToggleUserState(userUri) {
            return this.userStore.isLoadingChange(userUri);
        },
        clientFormatter(clientIri) {
            return this.clientStore.getClientByIri(clientIri)?.name;
        },
        editUser(user) {
            this.editModal.title = `Benutzer "${user.username}" bearbeiten`;
            this.$root.$emit('bv::show::modal', this.editModal.id);
            this.editModal.selectedUser = user;
        },
        resetEditModal() {
            this.editModal.user = {};
            this.$root.$emit('bv::hide::modal', this.editModal.id);
        },
        async handleSubmit(payload) {
            payload.user = this.editModal.selectedUser['@id'];
            const user = await this.userStore.change(payload);
            if (user) {
                this.alertStore.success(`Der Benutzer "${user.username}" wurde erfolgreich geändert.`, 'Benutzer geändert');
                this.resetEditModal();
            } else {
                this.alertStore.error('Benutzer ändern fehlgeschlagen', 'Upps! :-(');
            }
        },
        toggleEnabled: function (user, isEnabled) {
            if (isEnabled) {
                this.userStore.disable({user: user['@id']});
            } else {
                this.userStore.enable({user: user['@id']});
            }
        },
        switchUser(user) {
            this.authStore.switchUser(user);
        },
        exitSwitchUser() {
            this.authStore.exitSwitchUser();
        },
    },
};
</script>

<style scoped lang="scss">
</style>
